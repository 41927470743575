<template>
  <v-tooltip
    color="rgb(40, 82, 132)"
    right
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        :href="item.href"
        :rel="item.href ? 'nofollow' : undefined"
        :target="item.href ? '_blank' : undefined"
        :to="item.to"
        active-class="primary white--text"
        link
        class="py-1"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <v-list-item-icon
          v-if="!item.icon"
          class="text-caption text-uppercase justify-center ml-1 my-2 align-self-center"
        >
          {{ title }}
        </v-list-item-icon>

        <v-list-item-avatar v-if="item.avatar">
          <v-img :src="item.avatar" />
        </v-list-item-avatar>

        <v-list-item-icon
          v-if="item.icon"
          class="my-2 align-self-center"
          :v-bind="mini? attrs: ''"
          v-on="mini? on: ''"
        >
          <v-icon v-text="item.icon" />
        </v-list-item-icon>

        <v-list-item-content v-if="item.titleId">
          <v-list-item-title v-text="$t(item.titleId)" />
        </v-list-item-content>
      </v-list-item>
    </template>
    <span>{{ $t(item.titleId) }}</span>
  </v-tooltip>
</template>

<script>
  import { sync } from 'vuex-pathify'

  export default {
    name: 'DefaultListItem',

    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      title () {
        const matches = this.item.title.match(/\b(\w)/g)

        return matches.join('')
      },
      ...sync('app', [
        'mini',
      ]),
    },
  }
</script>

<style lang="scss">
$tooltip-font-size: 10px !important;
</style>
